// extracted by mini-css-extract-plugin
export var column = "ProjectHeader__column__rZibX";
export var container = "ProjectHeader__container__qgqA_";
export var flex = "ProjectHeader__flex__AtFkq";
export var flexColumn = "ProjectHeader__flexColumn__UScg_";
export var gap1 = "ProjectHeader__gap1__gY_lo";
export var gap2 = "ProjectHeader__gap2__e15QG";
export var gap3 = "ProjectHeader__gap3__UjhVz";
export var gap4 = "ProjectHeader__gap4__s75MP";
export var gap5 = "ProjectHeader__gap5__HdfUB";
export var projectTitle = "ProjectHeader__projectTitle__CNZJU";
export var row = "ProjectHeader__row__vY7vr";