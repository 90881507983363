// extracted by mini-css-extract-plugin
export var badge = "ProjectProviderPanel__badge__ag7YF";
export var badgeDenied = "ProjectProviderPanel__badgeDenied__mBLOY";
export var badgeInvited = "ProjectProviderPanel__badgeInvited__D6Tn9";
export var badgeRegRequested = "ProjectProviderPanel__badgeRegRequested__NaP3Q";
export var badgeRegistered = "ProjectProviderPanel__badgeRegistered__AOtnm";
export var badgeUninvited = "ProjectProviderPanel__badgeUninvited__pfj1L";
export var badgeUnresponsive = "ProjectProviderPanel__badgeUnresponsive__UtlVN";
export var badgeWrapper = "ProjectProviderPanel__badgeWrapper__W7Mp_";
export var column = "ProjectProviderPanel__column__QsME6";
export var datacentersCountText = "ProjectProviderPanel__datacentersCountText__Mf5He";
export var editButton = "ProjectProviderPanel__editButton__zuU2P";
export var eventInfo = "ProjectProviderPanel__eventInfo__eAYHm";
export var flex = "ProjectProviderPanel__flex__JmbY2";
export var flexColumn = "ProjectProviderPanel__flexColumn__Xwz8p";
export var gap1 = "ProjectProviderPanel__gap1__vrrDH";
export var gap2 = "ProjectProviderPanel__gap2__LcLRS";
export var gap3 = "ProjectProviderPanel__gap3__C57Ex";
export var gap4 = "ProjectProviderPanel__gap4___4QkU";
export var gap5 = "ProjectProviderPanel__gap5__eIZvd";
export var headingContentWrapper = "ProjectProviderPanel__headingContentWrapper__j7V2O";
export var hidden = "ProjectProviderPanel__hidden__pMZuM";
export var icon = "ProjectProviderPanel__icon__ziEoa";
export var mapContainer = "ProjectProviderPanel__mapContainer__K_lDq";
export var mapElement = "ProjectProviderPanel__mapElement__GerVz";
export var marginLeft = "ProjectProviderPanel__marginLeft__wP7fw";
export var marginRight = "ProjectProviderPanel__marginRight__wAVWN";
export var panelContainer = "ProjectProviderPanel__panelContainer__rY7W5";
export var panelHeading = "ProjectProviderPanel__panelHeading__FRjo6";
export var providerDetailRow = "ProjectProviderPanel__providerDetailRow__AS6w9";
export var providerImage = "ProjectProviderPanel__providerImage__lRBxd";
export var providerName = "ProjectProviderPanel__providerName__Tyvta";
export var providerPanel = "ProjectProviderPanel__providerPanel__UdSEM";
export var providerPanelBody = "ProjectProviderPanel__providerPanelBody__NLA1N";
export var providerPanelHeadingContainer = "ProjectProviderPanel__providerPanelHeadingContainer__kuJQ3";
export var providerPanelImageAndNameWrapper = "ProjectProviderPanel__providerPanelImageAndNameWrapper__FJRYC";
export var providerStatusButtonContainer = "ProjectProviderPanel__providerStatusButtonContainer__h5MvK";
export var providerTable = "ProjectProviderPanel__providerTable__K1F6A";
export var providerTableHead = "ProjectProviderPanel__providerTableHead__EKiGW";
export var providerTableHeadingContainer = "ProjectProviderPanel__providerTableHeadingContainer__HYz8Z";
export var providerTitle = "ProjectProviderPanel__providerTitle__gKe1o";
export var providersWrapper = "ProjectProviderPanel__providersWrapper__cWQZE";
export var quantityText = "ProjectProviderPanel__quantityText__VPVad";
export var reviewStatusWrapper = "ProjectProviderPanel__reviewStatusWrapper__Mm9jX";
export var roleText = "ProjectProviderPanel__roleText__wTqPT";
export var row = "ProjectProviderPanel__row__R9Det";
export var sizeLg = "ProjectProviderPanel__sizeLg__aGuCx";
export var sizeMd = "ProjectProviderPanel__sizeMd__gKSn1";
export var sizeSm = "ProjectProviderPanel__sizeSm__KqZ_J";
export var sizeXl = "ProjectProviderPanel__sizeXl__i1Sjb";
export var sizeXs = "ProjectProviderPanel__sizeXs___W6nt";
export var sizeXxl = "ProjectProviderPanel__sizeXxl__TLYbd";
export var sizeXxs = "ProjectProviderPanel__sizeXxs__Ah8LI";
export var sizeXxxl = "ProjectProviderPanel__sizeXxxl__Baf2T";
export var sizeXxxs = "ProjectProviderPanel__sizeXxxs__D_DzS";
export var sizeXxxxl = "ProjectProviderPanel__sizeXxxxl__yLmim";
export var sizeXxxxxl = "ProjectProviderPanel__sizeXxxxxl__HgGYW";