// extracted by mini-css-extract-plugin
export var column = "UserProjectDetailsPanel__column__DtAKz";
export var flex = "UserProjectDetailsPanel__flex__wVcd1";
export var flexColumn = "UserProjectDetailsPanel__flexColumn__ClcYs";
export var gap1 = "UserProjectDetailsPanel__gap1__P9udG";
export var gap2 = "UserProjectDetailsPanel__gap2__tgNbY";
export var gap3 = "UserProjectDetailsPanel__gap3___ISd7";
export var gap4 = "UserProjectDetailsPanel__gap4__FM8eZ";
export var gap5 = "UserProjectDetailsPanel__gap5__E3yeP";
export var hidden = "UserProjectDetailsPanel__hidden__nhQH0";
export var icon = "UserProjectDetailsPanel__icon__RYA55";
export var marginLeft = "UserProjectDetailsPanel__marginLeft__v8nKq";
export var marginRight = "UserProjectDetailsPanel__marginRight__nk9NL";
export var panelContainer = "UserProjectDetailsPanel__panelContainer__qEBSj";
export var panelHeading = "UserProjectDetailsPanel__panelHeading__erVdq";
export var row = "UserProjectDetailsPanel__row__qT5C_";
export var sizeLg = "UserProjectDetailsPanel__sizeLg__ucg0e";
export var sizeMd = "UserProjectDetailsPanel__sizeMd__YQLRs";
export var sizeSm = "UserProjectDetailsPanel__sizeSm__hTz9u";
export var sizeXl = "UserProjectDetailsPanel__sizeXl__xRUfA";
export var sizeXs = "UserProjectDetailsPanel__sizeXs__ss0QO";
export var sizeXxl = "UserProjectDetailsPanel__sizeXxl__a3cEF";
export var sizeXxs = "UserProjectDetailsPanel__sizeXxs__e659o";
export var sizeXxxl = "UserProjectDetailsPanel__sizeXxxl___yeIP";
export var sizeXxxs = "UserProjectDetailsPanel__sizeXxxs__dakVY";
export var sizeXxxxl = "UserProjectDetailsPanel__sizeXxxxl__qytib";
export var sizeXxxxxl = "UserProjectDetailsPanel__sizeXxxxxl__x2X4n";