// extracted by mini-css-extract-plugin
export var column = "ProjectProviderStatusButton__column__ip7Eu";
export var flex = "ProjectProviderStatusButton__flex__UEQgC";
export var flexColumn = "ProjectProviderStatusButton__flexColumn__pXFEF";
export var gap1 = "ProjectProviderStatusButton__gap1__Ff9b6";
export var gap2 = "ProjectProviderStatusButton__gap2__KhD8r";
export var gap3 = "ProjectProviderStatusButton__gap3__k3T9A";
export var gap4 = "ProjectProviderStatusButton__gap4__anNR_";
export var gap5 = "ProjectProviderStatusButton__gap5__ZAb4b";
export var popoverBody = "ProjectProviderStatusButton__popoverBody__JJ4vV";
export var popoverBodyLink = "ProjectProviderStatusButton__popoverBodyLink__HAoRr";
export var popoverBodyLinkIcon = "ProjectProviderStatusButton__popoverBodyLinkIcon__eT2hK";
export var row = "ProjectProviderStatusButton__row__zZwgb";